
.subHeader {
    padding: 20px;
    // background-color: #2E236C;
    background-color: #185519;

    color: #fff;
    .backArrow{
        font-size: 30px;
        margin-right: 40px;
        transition: .5s ease;
        color: #fff;
        &:hover{
            transform: translateX(-5px);
            color: #807e7efc;
        }
    }
}