

body::-webkit-scrollbar {
    width: 9px;
  }
   

   
  body::-webkit-scrollbar-thumb {
    background-color: #185519;
    outline: 0.2px solid #fff;
    border-radius: 10px;

  }