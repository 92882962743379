@import '../../assets/scss/mixin';

header{
    padding: 20px;
    // background-color: #2E236C;
    background-color: #185519;
    color: #fff;
    .logo{
        img{
            @include responsive(200px, 576px){
                width: 200px !important;
            }
        }
    }
    ul{
        margin: 0;
        padding: 0;
        li{
            margin: 0;
            padding: 0;
            list-style: none;
            margin-left: 20px;
            a{
                font-size: 20px;
                font-weight: bold;
                color: #fff;
                text-decoration: none;
                img{
                    transition: .3s ease;
                    @include responsive(200px, 576px){
                        width: 30px !important;
                    }
                    &:hover{
                        transform: translateY(-3px);
                        filter: brightness(0.7);
                    }
                }
              
            }
            button{
                img{
                    transition: .3s ease;
                    @include responsive(200px, 576px){
                        width: 30px !important;
                    }
                    &:hover{
                        transform: translateY(-3px);
                        filter: brightness(0.7);
                    }
                }
               
            }
        }
    }
}
