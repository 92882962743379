

.formComponent{
    .headText{
        margin-top: 30px;
        margin-bottom: 40px;
    }
    .myForm{
        padding: 20px;
        border: 0.5px solid #cccccc8b;
        input{
            display: block;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        label{
            color: #2E236C;
            font-size: 17px;
            font-weight: 500;
        }
    }
}