
.loginPage{
    .loginPhoto{
        img{
            margin-bottom: 40px;
        }
    }
    
    .loginForm{
        span{
            display: block;
            margin-top: -20px;
            margin-bottom: 20px;
            color: darkred;
            font-weight: 500;
        }
        input{
            display: block;
            margin-bottom: 20px;
            width: 100%;
            padding: 10px;
            border-radius: 8px;
            outline: none;
            border: 0.5px solid #c2bfbfd3;
        }
        .loginBtn{
            width: 100%;
            padding: 10px;
            font-weight: 600;
            font-size:18px;
            // background-color: #059212;
            // color: #fff;
            border-radius: 8px;
        }
        label{
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 2px;
            color: #2E236C;
        }
    }
}