.dataTableComponent {
    margin-top: 50px;
    padding: 10px;

    table {
        border-collapse: collapse;
        thead {
            background-color: #45484a;

            color: #fff;

            th {
                border: none;
                font-size: 15px;
            }
        }

        td,
        th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
            text-overflow: ellipsis;
        }
        td{

            img{
                width: 30px;

            }
        }

        tbody {
            overflow-x: auto;
            overflow-y: scroll;

            tr {
                transition: .3s ease;
                cursor: pointer;
                font-weight: 600;

                &:hover {
                    background-color: #dddddd;
                    color: #376c23d4;
                    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

                }
            }
        }
    }
}