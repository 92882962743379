
.agendaItem{
    margin-top: 20px;
    border-top: 10px solid darkgreen;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    transition: .3s ease;
    &:hover{
        transform: scale(1.1);
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 12px;
    }
   .agendaItemBorder{
    padding: 15px 5px;
    background-color: rgba(237, 232, 232, 0.475);
    border: 2px solid rgba(231, 228, 228, 0.709);
    .agendaItemHead{
        .personName{
            h4{
                font-size: 16px;
            }
        }
        .hour{
            h6{
                font-size: 13px;
            }
        }
    }
    .agendaItemDescription{
        .headDesc{
            h4{
                padding: 5px;
                background-color: #5C636A;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;

                color: #fff;
                font-size: 13px;
            }
            p{
                font-size: 14px;
            }
        }
        .bottomDesc{
            .btns{
                width: 100px;
            }
        }
    }
   }
}