@import '../../assets/scss/mixin';



.itemElement {
    margin-top: 20px;
    border: 1px solid #dfdddd;
    padding: 20px;
    transition: .3s ease;
    border-radius: 8px;
    &:hover{
        transform: scale(1.1);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
    .textLink {
        text-decoration: none !important;
        // color: #2E236C;
        color: #185519;
        
    }

    .elementBox {
      
        .elementImgBox {
            @include responsive(275px, 576px){
              width: 100px;
              margin: auto;
            }
            img {
              
                width: 100%;
                object-fit: cover;
              
            }
           
        }

        .elementTextBox {
            margin-top: 10px;
            display: flex;
            justify-content: center;
            h1 {
                text-align: center;
                text-decoration: none !important;
                font-size: 18px;
                font-weight: bolder;
                text-align: center;
            }
        }
    }
}